import React, { useState } from "react";
import styles from "./Footer.module.css";
import dextools from "../../assets/images/platforms/dextools.png";
import pancake from "../../assets/images/platforms/pancake.png";
import pinksale from "../../assets/images/platforms/pinksale.png";
import poocoin from "../../assets/images/platforms/poocoin.png";
import dexview from "../../assets/images/platforms/dexview.png";


const Platforms = () => {
  const [isHovered, setIsHovered] = useState({ 1: false, 2: false, 3: false, 4: false, 5: false, 6: false });

  return (
    <footer className={styles.footer}>
      <div className={styles.platforms}>
        <a href="https://pancakeswap.finance/swap?outputCurrency=0x50C7584e73D20760f1942822C968C8" target="_blank" rel="noreferrer">
          <img src={pancake} alt="pancake" />
        </a>
        <a href="https://poocoin.app/tokens/0x50C7584be73D20760f1947fF2968C8" target="_blank" rel="noreferrer">
          <img src={poocoin} alt="poocoin" />
        </a>
        <a href="https://www.dextools.io/app/en/bnb/pair-explorer/064006d6bd198656bd9f3d2a05afe9747" target="_blank" rel="noreferrer">
          <img src={dextools} alt="dextools" />
        </a>
        <a href="https://www.pinksale.finance/launchpad/0x1D94b48B6e9c91a393862Cb6b8905CB?chain=BSC" target="_blank" rel="noreferrer">
          <img src={pinksale} alt="pinksale" />
        </a>
        <a href="https://www.dexview.com/bsc/0x50C7584be73D20760ffFcbF794282C968C8" target="_blank" rel="noreferrer">
          <img src={dexview} alt="dexview" className={styles.dexview} />
        </a>
      </div>
    </footer>
  );
};

export default Platforms;
