import React from 'react';
import styles2 from '../Create/Create.module.css';
import styles from './Info.module.css';
import Navbar from '../../components/Navbar2/Navbar';
import Footer from '../../components/Footer2/Footer';
import BlinkSquares from '../../components/BlinkSquares/BlinkSquares';
import { useLocation } from 'react-router-dom';
import useNotyf from '../../hooks/useNotyf';
import { useNetwork } from 'wagmi';

const Info = ({ name, symbol, totalSupply, address }) => {
  const { state } = useLocation();
  const notyf = useNotyf();
  const { chain } = useNetwork();

  // if getting value from redirect
  if (state) {
    var txnHash = state.transactionHash;
    name = state.name;
    symbol = state.symbol;
    address = state.address;
    totalSupply = state.totalSupply;
  }
  console.log('address:', address);

  function chainExplorer() {
    if (!chain || chain.id === 1) {
      return 'https://etherscan.io';
    } else if (chain.id === 97) {
      return 'https://testnet.bscscan.com';
    } else if (chain.id === 56) {
      return 'https://bscscan.com';
    } else if (chain.id === 137) {
      return 'https://polygonscan.com';
    }
  }

  return (
    <div className="create-token-wrapper">
      <Navbar />
      <div className={`${styles2.container} ${styles.container}`}>
        <h3>Your Token was created!</h3>

        <div>
          <p>Name</p>
          <p>{name}</p>
        </div>
        <div>
          <p>Symbol</p>
          <p>{symbol}</p>
        </div>
        <div>
          <p>Total Supply</p>
          <p>{totalSupply}</p>
        </div>
        <div>
          <p>Address</p>
          <p>{address}</p>
        </div>

        <div>
          <button onClick={() => window.open(`${chainExplorer()}/tx/${txnHash}`, '_blank', 'noopener noreferrer')}>View Transaction</button>
          <button
            onClick={async () => {
              await navigator.clipboard.writeText(address);
              notyf.success('Address copied to clipboard');
            }}
          >
            Copy Address
          </button>
        </div>
      </div>
      <Footer />

      <BlinkSquares />
    </div>
  );
};

export default Info;
