import React, { useEffect, useState } from 'react';
import styles from './SelectModal.module.css';
import { RxCross2 } from 'react-icons/rx';
import { AiOutlineSearch } from 'react-icons/ai';
import { ethers } from 'ethers';
import tokenAbi from '../../../../abi/tokenAbi.json';
import { useNetwork, useProvider } from 'wagmi';
import tokenOptions from '../../utils/options';
import useNotyf from '../../../../hooks/useNotyf';

function List({ data, coinSelecteds, toggleModal }) {
  return (
    <li
      className={styles.listContainer}
      onClick={() => {
        coinSelecteds(data);
        toggleModal();
      }}
    >
      <img src={data.image} alt={data.symbol} />
      <span>
        <p> {data.name}</p>
        <p> {data.symbol}</p>
      </span>
    </li>
  );
}

const SelectModal = ({ isOpen, setIsOpen, setCoinSelectedParent }) => {
  // change options according to chain
  const notyf = useNotyf();

  const { chain } = useNetwork();
  const provider = useProvider();
  const [allOptions, setAllOptions] = useState(tokenOptions(chain?.id));
  const [options, setOptions] = useState(allOptions);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setAllOptions(tokenOptions(chain?.id));
    setOptions(tokenOptions(chain?.id));
  }, [chain]);

  function toggleModal() {
    setIsOpen((prev) => !prev);
  }
  const [coinSelected, setCoinSelected] = useState('');

  useEffect(() => {
    setCoinSelectedParent(coinSelected);
  }, [coinSelected]);

  function handleChange(e) {
    const { value } = e.target;
    setInputValue(value);
    setOptions(allOptions.filter((option) => option.name.toLowerCase().includes(value.toLowerCase())));
  }

  async function handleKeyDown(e) {
    if (e.key === 'Enter') {
      try {
        const token = new ethers.Contract(inputValue, tokenAbi, provider);
        const importToken = {
          name: await token.name(),
          symbol: await token.symbol(),
          image: 'https://etherscan.io/images/main/empty-token.png',
          address: inputValue,
        };

        setAllOptions((prev) => [...prev, importToken]);
        notyf.success('Token imported successfully');
      } catch (error) {
        console.log(error);
        notyf.error('Invalid address');
      }
    }
  }

  return (
    isOpen && (
      <div className={styles.modal}>
        <div className={styles.header}>
          <p>Select a token </p>
          <RxCross2 size={window > 700 ? 20 : 15} onClick={toggleModal} />
        </div>
        <div className={styles.inputContainer}>
          <AiOutlineSearch size={window > 700 ? 20 : 15} className={styles.search} color="white" />
          <input type="text" placeholder="Search name or paste address" onChange={handleChange} onKeyDown={handleKeyDown} value={inputValue} />
        </div>
        <div className={styles.line}></div>
        <div className={styles.optionContainer}>
          {options.map((option) => {
            return <List data={option} coinSelecteds={setCoinSelected} toggleModal={toggleModal} />;
          })}
        </div>
      </div>
    )
  );
};

export default SelectModal;
