import React from 'react'
import "./coming.css"
import Navbar from '../Navbar/Navbar'


const Coming = () => {
  return (
    <>
    <div className='main_coming'>
    <Navbar />
    <div className='comingsoon'>
    <h2>Coming Soon</h2>
    </div>
    </div>
    </>
  )
}

export default Coming