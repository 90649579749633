import React, { useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import Navbar from "../../components/Navbar/Navbar";
import styles from "./Home.module.css";
import work from "../../assets/images/work.png";
import trust from "../../assets/images/trust.png";
import Button from "../../components/Button/Button";
import Info from "../../components/Info/Info";
import Roadmap from "../../components/Roadmap/Roadmap";
import Footer from "../../components/Footer2/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import elipse1 from "../../assets/images/Ellipse1.png";
import Platforms from "../../components/Footer/Footer";
import tai1 from "../../assets/images/tai/swap1 trust ai 1.png";
import tai2 from "../../assets/images/tai/contract trust ai 1.png";
import tai3 from "../../assets/images/tai/nft trust ai 1.png";
import tai4 from "../../assets/images/tai/staking trust ai 1.png";
import tokenomics from "../../assets/images/tai/chart.png";
import { IoCopy, IoCheckmarkDoneCircleSharp } from 'react-icons/io5'
import { IoIosDoneAll } from 'react-icons/io'
import copy from 'copy-to-clipboard';


const Home = ({ home, about, token, road, team, partners }) => {



  const [modalOpen, setModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false)
  const [isShowMore, setIsShowMore] = useState(false);


  const copyToClipboard = () => {
    const res = copy("0x50c7584be73d20760f1947ffcsbf7942822c968c8")
    if (res != null) {
      setIsCopied(true)
      console.log("copied")
    } else {
      console.log("failed")
    }
  }


  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  useEffect(() => {
    AOS.init();
  }, []);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <>
      <div className={styles.outer_home}>
        <div className={styles.background}>
          <Navbar />
          <section className={styles.home} id="home">
            <div className={styles.homeContent}>
              <div className={styles.header} data-aos="fade-up">
                Welcome to Bet2Win
              </div>
              <div className={styles.title} data-aos="fade-up" data-aos-duration="1500">
              Betting Ecosystem Based On Binance Chain
              </div>
              <div className={styles.point}>
                <a href="https://www.pinksale.finance/launchpad/0x8c00457FDa63E5b53449F4463081048170E4cE9B?chain=BSC" target="_blank" rel="noreferrer">
                  <div className={styles.purple}>Safu</div>
                </a>
                <a href="https://github.com/CFG-NINJA/audits/blob/d8b937a9a510124d46645d5de2a0842c8fb83483/20231016_CFGNINJA_Bet2Win_B2W_Audit.pdf" target="_blank" rel="noreferrer">
                  <div className={styles.blue}>Audit</div>
                </a>
              </div>
            </div>
            <img className={styles.elipse1} src={elipse1} alt="elipse" />
          </section>
        </div>
        <section id="about" className={styles.intro}>
          <div className={styles.introContent}>
            <p className={styles.introHead} data-aos="fade-left" data-aos-duration="800">
              Introduction
            </p>
            <p className={styles.introInfo}>Are you ready to take your intuition and knowledge to the next level? Welcome to Bet2Win, your premier destination for predictive betting in the crypto world. At Bet2Win, we've revolutionized the betting experience by offering a dynamic platform where users can bet on anything under the sun, from the outcomes of political elections and thrilling sports events to the ever-evolving world of finance and current affairs.</p>
         
  
          </div>
          <div className={styles.workContent}>
            <div className={styles.left}>
              <p className={styles.workHead} data-aos="fade-left" data-aos-duration="800">
                How does it work?
              </p>
              <p className={styles.workInfo}>Bet on sports, politics, finance, and more – the possibilities are endless! <br></br>
Win real money by making accurate predictions on outcomes.
xperience the thrill of victory as your bets come to life.
Join us at Bet2Win and let the games begin. It's time to turn your predictions into profits and take your betting journey to new heights! <br></br>
From political outcomes and sports showdowns to entertainment awards and global phenomena, our platform lets you take a stance, place your bet, and stand a chance to reap rewards. With Bet2win, you’re not just a spectator; you're a player.
</p>
            </div>
            <div className={styles.right}>
              <img style={{ margin: "auto" }} className={styles.workImg} src={work} data-aos="zoom-out" data-aos-duration="800" alt="work" />
            </div>
            <img className={styles.elipse2} src={elipse1} alt="elipse" />
          </div>
        </section>
        <section className={styles.vision}>
          <div className={styles.visionContent}>
            <div className={styles.visionHead}>Our Misson</div>
            <div className={styles.visionInfo}>
            Our mission is to empower enthusiasts, analysts, and trendsetters to turn their insights into profit. With Bet2Win, you're not just watching the world unfold – you're actively participating, making predictions, and winning based on your expertise.
              {"\n"}
            </div>
            <div className={styles.visionInfo}>{"\n"}Join us on this journey as we blend the excitement of crypto with the thrill of prediction betting. Whether you're a pro or a newcomer looking to dip your toes into the world of crypto betting, Bet2Win is your trusted partner. Stay ahead of the game, make informed choices, and let your intuition guide you to victory.</div>
          </div>
        </section>
        <section className={styles.trust} >
          <div className={styles.trust_inner}>
            <div className={`${styles.row} ${styles.about_flex}`}>
              <div className={styles.imageDiv}>
                <img className={styles.trustImg} src={trust} alt="trustimage" />
                <div className={styles.elipse3} />
              </div>
              <div className={`${styles.col} ${styles.content_trust}`}>
                <div className={styles.trustHead} data-aos="fade-left" data-aos-duration="800">
                  What is Bet2Win Platform?{" "}
                </div>
                <div className={styles.trustInfo} data-aos="fade-up" data-aos-duration="800">
                Dive into a betting ecosystem where your funds and bets are as secure as the blockchain itself. With end-to-end encryption, smart contracts that guarantee transparency, and a decentralized system free from middlemen, what you see is what you get.
                </div>
                <a href="https://dapp.bet2win.space/" target="_blank" rel="noreferrer">
                  <Button label={"Bet Now"} />
                </a>
              </div>
            </div>
          </div>

          <div className={styles.bgdiv} id="swap">
        
        
            

            <div className={`${styles.Rcontainer2} tokenomics`} id="tokenomics">

              <div style={{ color: "white" }} className={styles.tokenomics_left}>
                <div className={styles.readyHead} data-aos="fade-right" data-aos-duration="800">
                  Tokenomics
                </div>
                <div className={styles.tokenomics_left_card}>
                  <p className={styles.gold}>Token Name - <span className={styles.light_white}>BET2WIN</span></p>
                  <p className={styles.gold}>Token Symbol - <span className={styles.light_white}>B2W</span></p>
                  <p style={{ whiteSpace: "nowrap" }} className={styles.gold}>Chain - <span className={styles.light_white} >BNB Smart Chain(BEP20)</span></p>
                </div>
                <p className={`${styles.gold} ${styles.big_font}`}>But & Sell Tax - <span className={styles.light_white}>6%</span></p>
                <a style={{ textAlign: 'center' }} href="https://pancakeswap.finance/swap?outputCurrency=0x5020760f19477942822C968C8" target="_blank" rel="noreferrer">
                  <Button label={"Buy now"} />
                </a>
              </div>
              <div className={styles.tokenomics_right}>
                <div className={styles.contract_address} style={{ display: 'flex', flexWrap: "nowrap", alignItems: 'center', justifyContent: 'center', gap: "5px" }}>
                  <p style={{ whiteSpace: "nowrap" }} className={styles.gold}>Contract Address - </p>

                  <p className={styles.light_white}>0x50c7584be760f1947ffcbf7942822c968c8 </p>
                  <button style={{ all: "unset" }} onClick={copyToClipboard}>
                    {isCopied ?
                      <IoCheckmarkDoneCircleSharp className={styles.gold} style={{ cursor: "pointer" }} />
                      :
                      <IoCopy className={styles.gold} style={{ cursor: "pointer" }} />
                    }
                  </button>
                </div>
                <img src={tokenomics} alt="" />
                <p className={`${styles.gold} ${styles.big_font}`}>Total Supply - <span className={styles.light_white}>10,000,000</span></p>
              </div>
            </div>
          </div>
        </section>
        <section id="roadmap">
          <Roadmap />
        </section>
      </div>
      <Platforms />
      <div  id="partners">
      <Footer/>
      </div>
    </>
  );
};

export default Home;
