import React, { useRef, useState, useEffect, useCallback } from 'react';
import './navbar.css';

import Logo from '../../assets/images/trust.png';
import menuIcon from '../../assets/images/icon-hamburger.svg';
import whitepaper from '../../assets/whitepaper.pdf';
import { Link } from 'react-router-dom';
import Modal from '../Modal/Modal';
import { ConnectButton } from '@rainbow-me/rainbowkit';

export default function Navbar({ navHeading = '' }) {
  const [menu, setmenu] = useState(false);
  const myNav = useRef();
  const myLogo = useRef();
  const myList = useRef();
  const [showModal, setShowModal] = useState(false);

  // const toggleMenu = () => {
  //   setmenu(!menu);
  // };

  useEffect(() => {
    if (menu) {
      myList.current.style.right = '0%';
    } else {
      myList.current.style.right = '-100%';
    }
  }, [menu]);

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal} />
      <div className="nav-wrapper" id="nav-wrapper" ref={myNav}>
        <div className="logo" ref={myLogo}>
          <img src={Logo} alt="Manage" />
          <li>{navHeading}</li>
        </div>
        <nav className="primary-navigation" ref={myList}>
          <ul className="nav-list" aria-label="Primary" id="primary-navigation">
            <li onClick={() => setmenu(false)}>
              <Link to="/">Home</Link>
            </li>
            <li>
              <a href="staking">Staking</a>
            </li>
            <li
              onClick={() => {
                setmenu(false);
                setShowModal(true);
              }}
            >
              <a>Create Contract</a>
            </li>
            <li
            // onClick={() => {
            //   setmenu(false);
            //   setShowModal(true);
            // }}
            >
              <a>NFT</a>
            </li>
            <li
            // onClick={() => {
            //   setmenu(false);
            //   setShowModal(true);
            // }}
            >
              <a href='#swap'>Swap</a>
            </li>
            <li>
              <ConnectButton label="Connect Wallet" chainStatus={'none'} />
            </li>
          </ul>
        </nav>
        <img
          onClick={() => {
            setmenu(!menu);
          }}
          className="menuIcon"
          src={menuIcon}
          alt="menu-icon"
        />
      </div>
    </>
  );
}
