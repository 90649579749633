export const phasesData = {
     phase1: [
          {
               head: "Website & Smart Contract Testing",
               sub: "Rigorous testing of website and smart contracts to ensure seamless functionality."
          },
          {
               head: "Social Media Creation",
               sub: "Crafting a compelling social media presence to engage and inform our community."
          },
          {
               head: "Audit and SAFU",
               sub: "Upholding security through comprehensive audits and Know Your Customer (KYC) protocols."
          },
          {
               head: "Presale on Pinksale",
               sub: "Launching on Pinksale for a Presale and transparent initiation."
          },
        
          {
               head: "Big Marketing Campaign",
               sub: "Initiating an impactful marketing campaign to generate awareness."
          },
          {
               head: "Dextools Update",
               sub: "Ensuring constant updates and alignment with Dextools for comprehensive tracking."
          }
     ],
     phase2: [
          {
               head: "500 Holders Milestone",
               sub: "Achieving a substantial community of 500 holders."
          },
          {
               head: "Website Update",
               sub: "Enhancing the website for an enriched user experience."
          },
          {
               head: "Massive Marketing",
               sub: "Amplifying marketing efforts to widen our reach."
          },
          {
               head: "Betting Platform Live",
               sub: "Betting platform will be live and people can bet on it"
          },
          {
               head: "Listing on CMC",
               sub: "Attaining recognition through listing on CoinMarketCap."
          },
        
     ],
     phase3: [
          {
               head: "1000 Holders Milestone",
               sub: "Celebrating the growth to 1000 holders."
          },
          {
               head: "Strong Marketing",
               sub: "Continuing robust marketing to drive community engagement."
          },
          {
               head: "Strong Partnership",
               sub: "Expanding strategic partnerships for mutual growth."
          },
          {
               head: "Telegram Bot",
               sub: "Bot to make TG betting easy and fun"
          }
     ]
};

