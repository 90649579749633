import React, { useRef } from "react";
import styles from "./SelectModal.module.css";
import { RxCross2 } from "react-icons/rx";

function List({ data, coinSelecteds, toggleModal }) {
  return (
    <li
      className={styles.listContainer}
      onClick={() => {
        coinSelecteds(data);
        toggleModal();
      }}
    >
      <img src={data.image} alt={data.symbol} />
      <span>
        <p> {data.name}</p>
        <p> {data.symbol}</p>
      </span>
    </li>
  );
}

const SettingModal = ({ isOpen, setIsOpen, setDeadline, setTolerance, tolerance, deadline }) => {
  const inputRef = useRef();
  function toggleModal() {
    setIsOpen((prev) => !prev);
  }

  return (
    isOpen && (
      <div className={styles.settingmodal}>
        <div className={styles.header}>
          <p>Setting</p>
          <RxCross2 size={window > 700 ? 20 : 15} onClick={toggleModal} />
        </div>
        <div >
          <p>Slippage Tolerance</p>
          <div className={styles.slippage_section}>
            <button className={styles.btn} onClick={() => (inputRef.current.value = 5)}>
              Auto
            </button>
            <input className={styles.input_slippage} placeholder="1" onChange={(e) => setTolerance(e.target.value)} ref={inputRef} value={tolerance} type="number" />
          </div>
        </div>
        <div >
          <p>Transaction deadline</p>
          <div className={styles.slippage_section}>
            <input className={styles.input_deadline} placeholder="30" onChange={(e) => setDeadline(e.target.value)} value={deadline} type="number" />
            <p>Minutes</p>
          </div>
        </div>
        <div className={styles.optionContainer}></div>
      </div>
    )
  );
};

export default SettingModal;
