import React, { useEffect, useState } from 'react';
import styles from './Create.module.css';
import Input from '../../components/Input/Input';
import Navbar from '../../components/Navbar2/Navbar';
import Footer from '../../components/Footer2/Footer';
import BlinkSquares from '../../components/BlinkSquares/BlinkSquares';
import { useNetwork, useProvider, useSigner, useSwitchNetwork } from 'wagmi';
import contractMakerAbi from '../../abi/contractMakerAbi.json';
import useNotyf from '../../hooks/useNotyf';
import { Contract, ethers, utils } from 'ethers';
import value from '../../value.json';
import { useNavigate } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';

const Create = () => {
  const navigate = useNavigate();
  const [tokenInfo, setTokenInfo] = useState({
    name: '',
    symbol: '',
    decimals: '',
    totalSupply: '',
    buyMarketingFee: '',
    buyLiquidityFee: '',
    sellMarketingFee: '',
    sellLiquidityFee: '',
    transferMarketingFee: '',
    transferLiquidityFee: '',
    marketingWallet: '',
    contractCreationFee: '0',
  });
  const [isLoading, setIsLoading] = useState(false);

  const { data: signer } = useSigner();
  const provider = useProvider();
  const notyf = useNotyf();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  // useEffect(() => {
  //   function getContractCreationFee() {
  //     const contractMaker = new Contract(value.contractMakerAddressBsc, contractMakerAbi, provider);

  //     contractMaker
  //       .getContractCreationFee()
  //       .then((res) => {
  //         setTokenInfo({ ...tokenInfo, contractCreationFee: ethers.utils.formatEther(res) });
  //       })
  //       .catch((err) => {
  //         setTokenInfo({ ...tokenInfo, contractCreationFee: 0 });
  //       });
  //   }

  //   getContractCreationFee();
  // }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setTokenInfo({ ...tokenInfo, [name]: value });
  }

  function contractMakerAddress() {
    if (!chain) throw new Error('Connect your wallet');
    if (chain.id === 56) {
      return value.contractMakerAddressBsc;
    } else if (chain.id === 1) {
      return value.contractMakerAddressEth;
    } else if (chain.id === 137) {
      return value.contractMakerAddressMatic;
    } else if (chain.id === 97) {
      return value.contractMakerAddressBscTest;
    } else {
      throw new Error('Chain not supported. Switch to polygon, eth or bsc');
    }
  }

  async function createToken(e) {
    e.preventDefault();

    if (!signer) {
      notyf.error('Please connect your wallet first.');
      return;
    }

    try {
      setIsLoading(true);
      // contract maker address dynamic according to chain
      const contractMaker = new Contract(contractMakerAddress(), contractMakerAbi, signer);

      const buyMarketingFee_ = utils.parseEther(tokenInfo.buyMarketingFee.toString());
      const buyLiquidityFee_ = utils.parseEther(tokenInfo.buyLiquidityFee.toString());
      const sellMarketingFee_ = utils.parseEther(tokenInfo.sellMarketingFee.toString());
      const sellLiquidityFee_ = utils.parseEther(tokenInfo.sellLiquidityFee.toString());
      const transferMarketingFee_ = utils.parseEther(tokenInfo.transferMarketingFee.toString());
      const transferLiquidityFee_ = utils.parseEther(tokenInfo.transferLiquidityFee.toString());

      const txn = await contractMaker.createContract(
        tokenInfo.name,
        tokenInfo.symbol,
        +tokenInfo.decimals,
        +tokenInfo.totalSupply,
        buyMarketingFee_,
        buyLiquidityFee_,
        sellMarketingFee_,
        sellLiquidityFee_,
        transferMarketingFee_,
        transferLiquidityFee_,
        tokenInfo.marketingWallet,
        {
          value: utils.parseEther(tokenInfo.contractCreationFee.toString()),
        }
      );
      const receipt = await txn.wait();
      console.log(receipt);
      setIsLoading(false);

      // send data to info component
      navigate('/info', { state: { transactionHash: receipt.transactionHash, address: receipt.logs[0].address, name: tokenInfo.name, symbol: tokenInfo.symbol, totalSupply: tokenInfo.totalSupply } });
    } catch (error) {
      setIsLoading(false);
      console.dir(error);
      if (error.message === 'Internal JSON-RPC error.') {
        notyf.error('Insufficient funds');
        return;
      }
      notyf.error(error?.error?.reason || error?.reason || error?.message || error);
    }
  }

  function handleChain(e) {
    if (!chain) notyf.error('Connect your wallet');
    switchNetwork(Number(e.target.value));
  }

  return (
    <>
      <ColorRing
        colors={['#d8aa2e', '#d8aa2e', '#d8aa2e', '#d8aa2e', '#d8aa2e', '#d8aa2e']}
        height={100}
        width={100}
        radius={5}
        color="white"
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        wrapperStyle={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 100, backdropFilter: 'blur(5px)' }}
        visible={isLoading}
      />
      <div className="create-token-wrapper">
        <Navbar navHeading={'Trust AI | Create Contract'} />
        <form className={styles.container} onSubmit={createToken}>
          <p>(*) is required field.</p>

          <div className={styles.selectContainer}>
            <label>Select Chain</label>
            <select onChange={handleChain} value={chain?.id}>
              <option value={56}>BNB Smart Chain</option>
              <option value={1}>Ethereum</option>
              <option value={137}>Polygon</option>
              <option value={97}>BSC Testnet</option>
            </select>
          </div>

          <div className={styles.inputContainer}>
            <Input label="Name" type="text" placeholder="Ex: Ethereum" required={true} value={tokenInfo.name} name="name" onChange={handleChange} />

            <Input label="Symbol" type="text" placeholder="Ex: ETH" required={true} value={tokenInfo.symbol} name="symbol" onChange={handleChange} />
          </div>

          <Input label="Decimals" type="number" placeholder="Ex: 18" required={true} value={tokenInfo.decimals} name="decimals" onChange={handleChange} />
          <Input label="Total supply" type="number" placeholder="Ex: 18" required={true} value={tokenInfo.totalSupply} name="totalSupply" onChange={handleChange} />

          <div className={styles.inputContainer}>
            <Input label="Buy Marketing Fee" type="number" placeholder="Ex: 0.01 BNB" required={true} value={tokenInfo.buyMarketingFee} name="buyMarketingFee" onChange={handleChange} />

            <Input label="Buy Liquidity Fee" type="number" placeholder="Ex: 0.01 BNB" required={true} value={tokenInfo.buyLiquidityFee} name="buyLiquidityFee" onChange={handleChange} />
          </div>

          <div className={styles.inputContainer}>
            <Input label="Sell Marketing Fee" type="number" placeholder="Ex: 0.01 BNB" required={true} value={tokenInfo.sellMarketingFee} name="sellMarketingFee" onChange={handleChange} />

            <Input label="Sell Liquidity Fee" type="number" placeholder="Ex: 0.01 BNB" required={true} value={tokenInfo.sellLiquidityFee} name="sellLiquidityFee" onChange={handleChange} />
          </div>

          <div className={styles.inputContainer}>
            <Input label="Transfer Marketing Fee" type="number" placeholder="Ex: 0.01 BNB" required={true} value={tokenInfo.transferMarketingFee} name="transferMarketingFee" onChange={handleChange} />

            <Input label="Transfer Liquidity Fee" type="number" placeholder="Ex: 0.01 BNB" required={true} value={tokenInfo.transferLiquidityFee} name="transferLiquidityFee" onChange={handleChange} />
          </div>

          <Input
            label="Marketing Wallet"
            type="text"
            placeholder="Ex: 0xFa60D973F7642B748046464e165A65B7323b0DEE"
            required={true}
            value={tokenInfo.marketingWallet}
            name="marketingWallet"
            onChange={handleChange}
          />

          <Input label="Contract Creation Fee" type="text" placeholder="0.5 BNB" required={false} disabled={true} value={tokenInfo.contractCreationFee} name="contactCreationFee" special={true} />

          {/* <div className={styles.antiBot}>
        <input type="checkbox" />
        <label>Implement Pink Anti-Bot System?</label>
      </div> */}

          <button type="submit" disabled={isLoading}>
            Create Token
          </button>
        </form>

        <BlinkSquares />
        <br />
        <br />
      </div>

      <Footer />
    </>
  );
};

export default Create;
