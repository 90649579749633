import React, { useState } from "react";
import social from "../../assets/images/socials/Vector.png";
import social1 from "../../assets/images/socials/Vector-2.png";
import social2 from "../../assets/images/socials/Vector-3.png";
import social3 from "../../assets/images/socials/Vector-4.png";
import social4 from "../../assets/images/socials/Vector-5.png";
import social6 from "../../assets/images/socials/Vector-6.png";
import "./Footer.css";

const Footer = () => {
     const [isHovered, setIsHovered] = useState({ 1: false, 2: false, 3: false, 4: false, 5: false, 6: false });

     const handleMouseEnter = (value) => {
          setIsHovered((prev) => {
               return { ...prev, [value]: true };
          });
     };

     const handleMouseLeave = (value) => {
          setIsHovered((prev) => {
               return { ...prev, [value]: false };
          });
     };

     const svgStyle = {
          width: "30px",
          height: "30px",
     };

     return (
          <footer className={'footer'}>
               <div className={'socials'}>
                    <div class="box">
                         <p class="text">JOIN OUR COMMUNITY</p>
                         <div class="social-box">
          
                              <a href="https://twitter.com/Bet2Winbw" target="__blank">
                                   <img src={social1} alt="" srcSet="" />
                              </a>
                              <a href="https://t.me/Bet2WinPortal" target="__blank">
                                   <img src={social2} alt="" srcSet="" />
                              </a>
                              <a href="https://mail.google.com/mail/?view=cm&to=contact%40bet2win.space" target="__blank">
                                   <img src={social6} alt="" srcSet="" />
                              </a>
                         </div>

                        
                         <p style={{ color: "white", marginTop: "2rem" }}>© 2023 Powered by the BET2WIN Team</p>
                    </div>
               </div>
          </footer>
     );
};

export default Footer;
