import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import React, { useRef } from 'react';
import Coming from './components/Coming/Coming';
import Stake from './pages/Stake/Stake';
import Create from './pages/Create/Create';
import Info from './pages/Info/Info';
import Swap from './pages/Swap/Swap';
import { getDefaultWallets, RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { bscTestnet, bsc, mainnet, polygon } from 'wagmi/chains';
import '@rainbow-me/rainbowkit/styles.css';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import value from './value.json';
import { Nftmint } from './pages/Nftmint/Nftmint';
import { AppProvider } from './pages/Swap/context/AppContext';

const projectId = process.env.REACT_APP_PROJECT_ID;

const { chains, provider } = configureChains(
  [bsc, bscTestnet, mainnet, polygon],
  [
    publicProvider(),
    jsonRpcProvider({
      rpc: (chain) => ({
        http: value.rpcUrl,
      }),
    }),
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  projectId,
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

function App() {
  const home = useRef(null);
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        initialChain={56}
        theme={darkTheme({
          accentColor: '#D8AA2E',
          accentColorForeground: 'white',
          overlayBlur: 'small',
          borderRadius: 'medium',
        })}
      >
        <AppProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/comingsoon" element={<Coming />} />
              <Route path="/staking" element={<Stake />} />
              <Route path="/createToken" element={<Create />} />
              {/* <Route path="/nftmint" element={<Nftmint />} /> */}
              <Route path="/info" element={<Info name={'Testing'} symbol={'test'} totalSupply={'1,000,000,000,000,000,000,000'} address={'0xcvfdbt67bdnjjsfhs773nbbhgy2a'} />} />
              {/* <Route path="/swap" element={<Swap />} /> */}
            </Routes>
          </Router>
        </AppProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
