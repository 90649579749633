import React from 'react';
import styles from './Button.module.css';

const Button = ({ label, handleClick }) => (
    <button
      className={styles.btn}
      onClick={handleClick}
    >
      {label}
    </button>
  );
  
  export default Button;