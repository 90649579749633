const BlinkSquares = () => {
  return (
    <>
      <div className="blinking-square square1"></div>
      <div className="blinking-square square2"></div>
      <div className="blinking-square square3"></div>
      <div className="blinking-square square4"></div>
      <div className="blinking-square square5"></div>
      <div className="blinking-square square6"></div>
      <div className="blinking-square square7"></div>
      <div className="blinking-square square7"></div>
      <div className="blinking-square square7"></div>
      <div className="blinking-square square8"></div>
      <div className="blinking-square square9"></div>
    </>
  );
};

export default BlinkSquares;
