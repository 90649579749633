import React, { useEffect } from 'react';
import styles from './Roadmap.module.css';
import circle from '../../assets/images/yellow_circle.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { phasesData } from './Phase';

const Roadmap = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className={styles.roadmap}>
      <h2 data-aos="fade-up" data-aos-duration="800">Roadmap</h2>
      <div className={styles.box1}>
        <img src={circle} alt="circle1" className={styles.circle1} />
        <ul>
          <h2 data-aos="fade-right" data-aos-duration="800" style={{ fontSize: "1.5rem", color: "#FEFAFA", alignSelf: "flex-start" }}>Phase 1</h2>
          {phasesData.phase1.map((phase) => {
            return <div data-aos="fade-right" data-aos-duration="800">
              <li >{phase.head}</li>
              <p style={{ color: "#FEFAFA", textAlign: "left" }}>{phase.sub}</p>
            </div>
          })}
        </ul>
      </div>
      <div className={styles.box2}>
        <img src={circle} alt="circle2" className={styles.circle2} />
        <ul>
          <h2 data-aos="fade-right" data-aos-duration="800" style={{ fontSize: "1.5rem", color: "#FEFAFA", alignSelf: "flex-start" }}>Phase 2</h2>
          {phasesData.phase2.map((phase) => {
            return <div data-aos="fade-right" data-aos-duration="800">
              <li >{phase.head}</li>
              <p style={{ color: "#FEFAFA", textAlign: "left" }}>{phase.sub}</p>
            </div>
          })}
        </ul>
        <div className={styles.elipse4} ></div>
      </div>
      <div className={styles.box3}>
        <img src={circle} alt="circle3" className={styles.circle3} />
        <ul>
          <h2 data-aos="fade-right" data-aos-duration="800" style={{ fontSize: "1.5rem", color: "#FEFAFA", alignSelf: "flex-start" }}>Phase 3</h2>
          {phasesData.phase3.map((phase) => {
            return <div data-aos="fade-right" data-aos-duration="800">
              <li >{phase.head}</li>
              <p style={{ color: "#FEFAFA", textAlign: "left" }}>{phase.sub}</p>
            </div>
          })}
        </ul>
      </div>
    
    </section>
  );
};

export default Roadmap;
