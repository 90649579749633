import React, { useState, useEffect } from "react";
import StakeNav from "../../components/StakeNav/StakeNav";
import styles from "./Stake.module.css";
import InfoBox from "../../components/InfoBox/InfoBox";
import StakeFoot from "../../components/StakeFoot/StakeFoot";
import { ethers } from "ethers";
import { Notyf } from "notyf";
import value from "../../value.json";
import stakingAbi from "../../abi/stakingAbi.json";
import tokenAbi from "../../abi/tokenAbi.json";
import axios from "axios";
import { useSigner, useProvider, useAccount } from "wagmi";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import Navbar from "../../components/Navbar2/Navbar";
import Footer from "../../components/Footer2/Footer";

const Stake = () => {
  const time = [3, 6, 12];

  useEffect(() => {
    console.log(poolId);
  }, [poolId, time]);

  const notyf = new Notyf({
    duration: 5000,
    position: { x: "right", y: "top" },
    dismissible: true,
    types: [
      {
        type: "error",
        background: "red", // Background color for error notification
        color: "#ffffff", // Text color for error notification
      },
    ],
  });
  const { isDisconnected, isConnected } = useAccount();
  const [stakingAddress, setStakingAddress] = useState(value.stakingAddress1);
  const { data: signer } = useSigner();
  const provider = useProvider();
  const [day, setDay] = useState(3);
  const [poolId, setPoolId] = useState(0);
  const [myStakeBalance, setMyStakeBalance] = useState(0);
  const [myTokenBalance, setMyTokenBalance] = useState(0);
  const [emergencyFee, setEmergencyFee] = useState(0);
  const [lockDeadline, setLockDeadline] = useState(0);
  const [isTokenApproved, setIsTokenApproved] = useState(false);
  const [amount, setAmount] = useState(0);
  const [apy, setApy] = useState(0);
  const [stakeHolders, setStakeHolders] = useState(0);
  const [totalTokensLocked, setTotalTokensLocked] = useState(0);
  const [tokenValue, setTokenValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const balanceInfo = [
    { title: "My Balance:", value: myTokenBalance },
    { title: "My Staked Balance:", value: myStakeBalance },
    { title: "Lock Deadline:", value: lockDeadline > 0 ? lockDeadline : 0 },
    { title: "APY Rate", value: typeof apy === "number" ? apy + "%" : apy },
  ];

  const staking = new ethers.Contract(stakingAddress, stakingAbi, signer ? signer : provider);
  const token = new ethers.Contract(value.stakingToken, tokenAbi, signer);

  useEffect(() => {
    refreshData(signer);
    getPoolInfo();
  }, [signer, poolId, stakingAddress]);

  function refreshData(signer) {
    if (signer) {
      getPoolInfo();
      getUserInfo();
      getTokenBalance();
      checkApproved();
    }
  }

  async function getPoolInfo() {
    try {
      let rpcUrl = value.rpcUrl;
      let provider_ = new ethers.providers.JsonRpcProvider(rpcUrl);
      const staking2 = new ethers.Contract(stakingAddress, stakingAbi, provider_);
      const token2 = new ethers.Contract(value.stakingToken, tokenAbi, provider_);
      let _poolInfo = await staking2.poolInfo(poolId);
      const decimals = await token2.decimals();
      const stakeHolders = await _poolInfo.stakeHolders;
      const totalTokensLocked = await _poolInfo.currentPoolSize;
      const apy = await staking2.getApy(poolId);
      console.log(apy);
      console.log("checkpoint2");
      console.log(poolId);
      setApy(apy.toString() + `%`);
      setStakeHolders(stakeHolders.toString());
      setTotalTokensLocked(ethers.utils.formatUnits(totalTokensLocked.toString(), decimals));
    } catch (err) {
      console.log("getPoolInfo");
      console.dir(err);
    }
  }

  async function getUserInfo() {
    try {
      let userAddress = await signer.getAddress();
      let _userInfo = await staking.userInfo(poolId, userAddress);
      const decimals = await token.decimals();
      const myStakeBalance = ethers.utils.formatUnits(_userInfo.amount.toString(), decimals);

      if (myStakeBalance == 3) {
        setMyStakeBalance(3);
        setLockDeadline("NA");
        return;
      }

      const currentTimestamp = Math.floor(Date.now() / 1000);
      const lockDeadlineTimestamp = await staking.getUserLockTime(poolId, userAddress);
      const lockDeadline = Math.ceil((lockDeadlineTimestamp - currentTimestamp) / 86400);
      setMyStakeBalance(myStakeBalance);
      setLockDeadline(lockDeadline);
    } catch (err) {
      console.log("getUserInfo", err);
      console.dir(err);
    }
  }

  async function getTokenBalance() {
    try {
      let userAddress = await signer.getAddress();
      const tokenbalance = await token.balanceOf(userAddress);
      console.log(tokenbalance);
      const decimals = await token.decimals();
      const tokenbalanceConverted = ethers.utils.formatUnits(tokenbalance.toString(), decimals);
      setMyTokenBalance(Math.floor(tokenbalanceConverted));
    } catch (err) {
      console.log("getTokenBalance", err.message);
    }
  }

  function handleClickDays(days) {
    console.log(days);

    if (days === 3) {
      setPoolId(0);
    } else if (days === 6) {
      setPoolId(1);
    } else if (days === 12) {
      setPoolId(2);
    }
    setDay(days);
    console.log(days);
  }

  async function checkApproved() {
    try {
      const userAddress = await signer.getAddress();
      const isApproved = await token.allowance(userAddress, stakingAddress);
      const totaltokenapproved = isApproved.toString();
      if (totaltokenapproved.length > 2) {
        console.log("approved", totaltokenapproved);
        setIsTokenApproved(true);
      } else {
        console.log("Not Approved", totaltokenapproved);
        setIsTokenApproved(false);
      }
    } catch (error) {
      console.log(error);
      // notyf.error(error.message);
    }
  }

  async function approve() {
    if (!isTokenApproved) {
      console.log("Not Approved");
      try {
        let _amount = ethers.utils.parseEther("10000000000000000000");
        let tx = await token.approve(stakingAddress, _amount);
        let reciept = await tx.wait();
        console.log("Approve Tx Receipt: ", reciept);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Already approved");
    }
  }

  async function stakeTokens() {
    setIsLoading(true);
    try {
      if (amount === undefined) {
        notyf.error("Please enter amount to stake");
        return;
      }

      await approve();
      let tempDecimals = await token.decimals();
      let _amount = ethers.utils.parseUnits(amount.toString(), tempDecimals);
      let tx = await staking.stakeTokens(poolId, _amount);
      let reciept = await tx.wait();
      console.log("Stake Tx Receipt: ", reciept);
      notyf.success("Staked Successfully and claimable rewards are sent to your wallet address if any");
      refreshData(signer);
    } catch (err) {
      console.log("stakeTokens", err.message);
      try {
        notyf.error(err.error.data.message);
      } catch {
        notyf.error("Something went wrong, please try again!");
      }
    }
    setIsLoading(false);
  }

  async function unstakeTokens() {
    setIsLoading(true);
    try {
      let tx = await staking.unstakeTokens(poolId);
      let reciept = await tx.wait();
      console.log("Unstake Tx Receipt: ", reciept);
      notyf.success("Unstaked Successfully");
      refreshData(signer);
    } catch (err) {
      console.log(err.message);
      try {
        notyf.error(err.error.data.message);
      } catch {
        notyf.error("Something went wrong, please try again!");
      }
    }
    setIsLoading(false);
  }

  function handleChange(event) {
    const { name, value } = event.target;
    if (name === "tokenAmount") {
      setAmount(value);
    }
  }

  return (
    <>
      <div className={styles.outer_home}>
        <div className={styles.background}>
        <Navbar navHeading={"Trust AI | Staking"} />

          <section className={styles.stake}>
            <div className={styles.infoContainer}>
              {/* <InfoBox title={"Total Token Locked"} value={`${totalTokensLocked}`} /> */}
              <InfoBox title={"APY"} value={apy} />
              <InfoBox title={"Stake Holders"} value={stakeHolders} />
            </div>
            <div className={styles.stakeContainer}>
              <div className={styles.leftContent}>
                <div className={styles.buttonContainer}>
                  {time.map((time) => {
                    return (
                      <div key={time}>
                        <button onClick={() => handleClickDays(time)} style={{ background: time === day ? " #D8AA2E" : "transparent" }}>
                          {time === 12 ? "1 Year" : time + " Months"}
                        </button>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.balanceContainer}>
                  {balanceInfo.map((info) => {
                    return (
                      <p key={info.title}>
                        {info.title} {info.title === "APY Rate" ? <br /> : null} <span className={styles.gradientText}>{info.value}</span>
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className={styles.rightContent}>
                <div className={styles.inputBox}>
                  <input type="number" placeholder="0.00" onChange={(e) => handleChange(e)} name="tokenAmount" value={amount} />
                  <button
                    onClick={() => {
                      setAmount(myTokenBalance);
                    }}
                  >
                    Max
                  </button>
                </div>
                <div className={styles.actionContainer}>
                  <button onClick={stakeTokens}>Stake</button>
                  <button onClick={unstakeTokens}>Unstake</button>
                </div>
                {isConnected && <></>}
                {isDisconnected && <ConnectButton chainStatus={"none"} />}
              </div>
            </div>
          </section>
          {/* <StakeFoot /> */}
          <Footer/>
        </div>
      </div>
    </>
  );
};

export default Stake;
