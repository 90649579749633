import React, { useState } from 'react';
import menuIcon from '../../assets/images/icon-hamburger.svg';
import Logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import Modal from '../Modal/Modal';
import { IoClose } from 'react-icons/io5'
import './navbar.css';



export default function Navbar() {
     const [menu, setmenu] = useState(false);
     const [showModal, setShowModal] = useState(false);
     return (
          <>
               <header>
                    <Modal showModal={showModal} setShowModal={setShowModal} />
                    <nav className="navbar">
                         <div className="logo">
                              <img src={Logo} alt="Manage" />
                         </div>
                         <ul className={`menu-list`} aria-label="Primary">
                              <NavList setmenu={setmenu} setShowModal={setShowModal} />
                         </ul>
                         {menu ?
                              <ul className={`mob-nav`} aria-label="Primary">
                                   <NavList setmenu={setmenu} setShowModal={setShowModal} />
                              </ul>
                              : ""
                         }

                         <div className='operator-btn'>
                              {menu ?
                                   <IoClose className='close-icon' onClick={() => { setmenu(v => !v) }} />
                                   :
                                   <img onClick={() => { setmenu(v => !v) }} className="hamberger-menu" src={menuIcon} alt="menu-icon" />
                              }
                         </div>
                    </nav>
               </header>
          </>
     );
}


function NavList({ setmenu, setShowModal }) {
     return (
          <>
               <li onClick={() => setmenu(false)}>
                    <Link to="/">Home</Link>
               </li>
               <li onClick={() => setmenu(false)}>
                    <a target="_blank" rel="noreferrer" href={"https://thematrixlabs.gitbook.io/whitepaper-bet2win"}>
                         Whitepaper
                    </a>
               </li>
               <li onClick={() => setmenu(false)}>
                    <a href="#about">About Us</a>
               </li>
               <li onClick={() => { setmenu(false); }}>
                    <a href="#roadmap">Roadmap</a>
               </li>

               <li onClick={() => { setmenu(false); }}>
                    <a href="#tokenomics">Tokenomics</a>
               </li>

               <li onClick={() => { setmenu(false); }}>
                    <a href="#partners">Partners</a>
               </li>

               <li onClick={() => { setmenu(false); }}>
                    <a href='https://dapp.bet2win.space/' target='_blank' rel='noreferrer' className='btn' style={{ textAlign: 'center' }} >
                         Dapp
                    </a>
               </li>
          </>
     )
}