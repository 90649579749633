import eth from '../assets/images/coins/ether.png';
import trustai from '../assets/images/coins/trustai.png';

function tokenOptions(chainId) {
  switch (chainId) {
    // ethereum
    case 1:
      return [
        // { name: 'Ethereum', symbol: 'ETH', image: eth, address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', coin: true },
        { name: 'Dai Stablecoin', symbol: 'DAI', image: 'https://etherscan.io/token/images/MCDDai_32.png', address: '0x6B175474E89094C44Da98b954EedeAC495271d0F' },
        { name: 'Tether', symbol: 'USDT', image: 'https://etherscan.io/token/images/tethernew_32.png', address: '0xdAC17F958D2ee523a2206206994597C13D831ec7' },
        { name: 'Wrapped Ether', symbol: 'WETH', image: 'https://etherscan.io/token/images/weth_28.png', address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', weth: true },
      ];
    // bsct
    case 97:
      return [
        { name: 'Binance Chain Native Token', symbol: 'tBNB', image: 'https://assets.pancakeswap.finance/web/chains/97.png', address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd', coin: true },
        {
          name: 'PancakeSwap Token',
          symbol: 'CAKE',
          image: 'https://tokens.pancakeswap.finance/images/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png',
          address: '0xFa60D973F7642B748046464e165A65B7323b0DEE',
        },
        { name: 'Wrapped BNB', symbol: 'WBNB', image: 'https://tokens.pancakeswap.finance/images/symbol/bnb.png', address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd', weth: true },
      ];
    // bsc
    case 56:
    case undefined:
      return [
        { name: 'Binance Chain Native Token', symbol: 'BNB', image: 'https://tokens.pancakeswap.finance/images/symbol/bnb.png', address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', coin: true },
        {
          name: 'TRUST AI',
          symbol: 'TRT',
          image: trustai,
          address: '0x50C7584be73D20760f1947fFcbF7942822C968C8',
        },
        {
          name: 'Tether USD',
          symbol: 'USDT',
          image: 'https://tokens.pancakeswap.finance/images/symbol/usdt.png',
          address: '0x55d398326f99059fF775485246999027B3197955',
        },
        // { name: 'Wrapped BNB', symbol: 'WBNB', image: 'https://tokens.pancakeswap.finance/images/symbol/bnb.png', address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', weth: true },
        { name: 'BTCB Token', symbol: 'BTCB', image: 'https://tokens.pancakeswap.finance/images/symbol/wbtc.png', address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c' },
      ];
    // polygon
    case 137:
      return [
        {
          name: 'Polygon Matic',
          symbol: 'MATIC',
          image: 'https://app.uniswap.org/static/media/matic-token-icon.da7b877d41122a55de4a43760bb4c8e5.svg',
          address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
          coin: true,
        },
        {
          name: 'Tether USD',
          symbol: 'USDT',
          image: 'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/polygon/assets/0xc2132D05D31c914a87C6611C10748AEb04B58e8F/logo.png',
          address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        },
      ];
    default:
      // bsc
      return [
        { name: 'Binance Chain Native Token', symbol: 'BNB', image: 'https://tokens.pancakeswap.finance/images/symbol/bnb.png', address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', coin: true },
        {
          name: 'TRUST AI',
          symbol: 'TRT',
          image: trustai,
          address: '0x50C7584be73D20760f1947fFcbF7942822C968C8',
        },
        {
          name: 'Tether USD',
          symbol: 'USDT',
          image: 'https://tokens.pancakeswap.finance/images/symbol/usdt.png',
          address: '0x55d398326f99059fF775485246999027B3197955',
        },
        // { name: 'Wrapped BNB', symbol: 'WBNB', image: 'https://tokens.pancakeswap.finance/images/symbol/bnb.png', address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', weth: true },
        { name: 'BTCB Token', symbol: 'BTCB', image: 'https://tokens.pancakeswap.finance/images/symbol/wbtc.png', address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c' },
      ];
  }
}

export default tokenOptions;
