import React from 'react';
import styles from './Input.module.css';

const Input = ({ value, onChange, name, type, required, placeholder, label, special, disabled = false }) => {
  return (
    <div className={styles.container}>
      <label>
        {label} {required && <span>*</span>}
      </label>
      <input className={special ? styles.special : ''} type={type} name={name} value={value} onChange={onChange} required={required} placeholder={placeholder} disabled={disabled} autoComplete="off" />
    </div>
  );
};

export default Input;
